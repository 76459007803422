import {
  faDiscord,
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
  faHive,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const social = [
  {
    name: 'Hive',
    link: 'https://peakd.com/@travelfeed',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faHive}
      />
    ),
  },
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/joinTravelFeed',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faFacebook}
      />
    ),
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/joinTravelFeed',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faXTwitter}
      />
    ),
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/@joinTravelFeed',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faYoutube}
      />
    ),
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/joinTravelFeed/',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faInstagram}
      />
    ),
  },
  {
    name: 'Discord',
    link: 'https://discord.gg/jWWu73H',
    icon: (
      <FontAwesomeIcon
        style={{ width: '22px', height: '22px' }}
        icon={faDiscord}
      />
    ),
  },
];

const SocialLinks = ({ alignLeft, isLight }) => {
  return (
    <>
      <div className={alignLeft ? undefined : 'text-center'}>
        {social.map(s => {
          return (
            <a
              key={s.name}
              href={s.link}
              title={s.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className={isLight ? 'text-light' : undefined}
                aria-label={s.name}
                size="large"
              >
                {s.icon}
              </IconButton>
            </a>
          );
        })}
      </div>
    </>
  );
};

export default SocialLinks;
