/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import Cookie from 'js-cookie';
import withApollo from 'next-with-apollo';
import React from 'react';
import { GRAPHQL_URL } from '../config';
import { sha256 } from 'crypto-hash';

const httpLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
}).concat(
  createHttpLink({
    uri: GRAPHQL_URL,
    credentials: 'same-origin',
  }),
);

const authLink = setContext((_, { headers }) => {
  const csrf = Cookie.get('csrf');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // CSRF protection
      authorization: csrf,
    },
  };
});

export default withApollo(
  ({ initialState }) => {
    return new ApolloClient({
      cache: new InMemoryCache().restore(initialState || {}),
      link: authLink.concat(httpLink),
      onError: ({ networkError, graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors);
        console.log('networkError', networkError);
      },
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  },
);
